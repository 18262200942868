<template>
  <div class="con" >
      <!-- {{sortTx}}111 -->
    <Header-top ref="headerTop"  @fabucangwei="fabucangwei" @hangxiangShow="hangxiangShow" ></Header-top>
    <div class="searchbox">
      <div class="search">
        <el-form :inline="true" class="lines" size="small">
          <!-- 起始港 -->
          <el-form-item>
            <el-select
              class="searchSelect"
              v-model="params.startSeaport"
              filterable
              clearable
              @clear="initData1"
              :filter-method="getFreightPort"
              placeholder="深圳"
              style="width: 319px"
            >
              <el-option
                v-for="item in FreightPortList1"
                :key="item.id"
                :label="item.name"
                :value="item.cname"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.cname
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 目的港 -->
          <!-- {{params.targetSeaport}} -->
          <el-form-item style="width: 319px">
            <el-select
              @visible-change="visibleChange"
              @change="blurChange"
              v-model="params.targetSeaport"
              filterable
              clearable
               @clear="initData1"
              :filter-method="getFreightPort1"
              placeholder="LOS ANGELES,CA"
              style="width: 319px"
            >
              <el-option
                v-for="item in FreightPortList3"
                :key="item.id"
                :label="item.name"
                :value="item.cname"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.cname
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 船司 -->
          <el-form-item>
            <el-select
              v-model="params.shipCompanyName"
              filterable
              clearable
               @clear="initData"
              :filter-method="getFreightCompany"
              placeholder="船司"
              style="width: 107px"
            >
              <el-option
                v-for="item in companyList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.cname
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 柜型 -->
          <el-form-item>
            <el-select
              v-model="params.cabinetShape"
              clearable
               @clear="initData"
              placeholder="柜型"
              style="width: 90px"
            >
              <el-option
                v-for="item in getFreightDefineEnumsList.boxModes"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 预计开船时间 -->
          <el-form-item>
            <el-date-picker
              v-model="value1"
              type="date"
               @clear="initData"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              placeholder="预计开船时间"
              style="width: 167px"
            >
            </el-date-picker>
          </el-form-item>
          <!-- 搜索/重置 -->
          <el-form-item>
            <el-button
              type="primary"
              @click="initData"
              style="
                width: 137px;
                backgroundcolor: #00bafd;
                border-color: #00bafd;
                height: 48px;
              "
            >
              <div style="display: flex; justify-content: center">
                <img
                  src="../../assets/images/search.png"
                  alt=""
                  srcset=""
                  style="width: 14px; height: 14px"
                />
                <span
                  style="
                    display: inline-block;
                    font-size: 13px;
                    margin-left: 10px;
                  "
                  >搜索</span
                >
              </div>
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div id="cons">
      <div class="contents">
        <div v-if="params.sailTime" class="weekNum">
          开船时间:{{yearObj}}
        </div>
         <!-- <div class="flex headerOrderBy">
             
              <div> 排序 </div> 
                
              <div style="margin-left:5px;margin-right:5px">
                    <el-select v-model="orderByModel" class="sortInput"   style="width:130px;height:30px" placeholder="请选择">
                    <el-option
                      v-for="item in orderByList"
                      :key="item.value"
                      :label="item.name"
                    
                      :value="item.value">
                    </el-option>
                  </el-select>
                  
                </div>
                <div  class="caret-wrapper" @click="sortClick">
                      <i class="el-icon-caret-top" :style="sortTx == 'asc' ? 'color:rgba(0, 186, 253, 1)' : ''"></i>
                      <i class="el-icon-caret-bottom" :style="sortTx == 'desc' ? 'color:rgba(0, 186, 253, 1)' : ''"></i>
                  
                  </div>
            </div> -->
        <el-table :data="tableData" style="width: 100%" height="100%">
          <!-- 起运港 -->
          <el-table-column label="起运港" width="120">
            <template slot-scope="scope">
              <p class="name1">{{ scope.row.startPortName1 }}</p>
              <p class="name2">{{ scope.row.startSeaport }}</p>
            </template>
          </el-table-column>
          <!-- 直达/中转 -->
          <el-table-column label="" width="100">
            <template slot-scope="scope">
              <div class="zz" style="height: 80px">
                <div
                  style="
                    font-size: 12px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                  "
                >
                  <div style="width: 30px; height: 30px; margin: 0px auto">
                    <!-- {{scope.row.logoUrl}} -->
                    <img
                      :src="scope.row.logoUrl"
                      alt=""
                      style="width: 30px; height: 30px"
                      v-show="scope.row.logoUrl"
                    />
                    <img
                      src="./../../assets/images/basicprofile.jpeg"
                      style="width: 100%; height: 100%; border-radius: 50%"
                      v-show="!scope.row.logoUrl"
                    />
                  </div>
                  <!-- <img :src="scope.row.logoUrl == '' ? scope.row.logoUrl : ''" alt="" style="width:40px;height:27px;margin:0 auto" > -->
                  <img src="./mth.png" alt="" srcset="" style="width: 70px" />
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      height: 20px;
                    "
                  >
                    <p
                      v-if="
                        scope.row.targetPortName == scope.row.transferPortName
                      "
                      class="method"
                    >
                      直达
                    </p>
                    <p v-else class="method">中转</p>
                    <p>{{ scope.row.voyageDay }}天</p>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- 目的港 -->
          <el-table-column prop="name" label="目的港">
            <template slot-scope="scope">
              <p class="name1">{{ scope.row.targetPortName }}</p>
              <p class="name2">{{ scope.row.targetSeaport }}</p>
            </template>
          </el-table-column>
          <!-- 航线信息 -->
          <el-table-column label="航线信息">
            <template slot-scope="scope">
              <div>
                <div>
                  <el-popover placement="bottom" trigger="hover">
                    <el-table
                      :data="scope.row.surchargeAmountDetail"
                      style="
                        width: 400px;
                        border: solid 0px #ff0000;
                        box-shadow: 0 0 0 0;
                      "
                    >
                      <el-table-column
                        prop="feeName"
                        label="名称"
                        align="center"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="totalFlag"
                        label="单位"
                        align="center"
                      >
                        <template>
                          <div v-show="scope.row.totalFlag">柜</div>
                          <div v-show="scope.row.totalFlag">票</div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="fee"
                        align="center"
                        label="单票价格"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="feeType"
                        align="center"
                        label="币种"
                      >
                      </el-table-column>
                    </el-table>
                    <div
                      slot="reference"
                      style="font-size: 12px; color: #1677ff; cursor: pointer"
                    >
                      附加费
                    </div>
                  </el-popover>
                </div>
                <div>
                  <el-tooltip placement="bottom">
                    <div slot="content">
                      截文件时间：{{ scope.row.closeFileTime
                      }}<br />截放行时间：{{ scope.row.closePassTime
                      }}<br />开船时间：{{ scope.row.sailTime }}
                    </div>
                    <div class=""  style="color: #acacac; font-size: 12px;cursor: pointer">
                      截关/开船：{{ scope.row.weekcloseFile }}/{{
                        scope.row.weekSail
                      }}
                    </div>
                  </el-tooltip>
                </div>

                <p style="color: #acacac; font-size: 12px">
                  备注：
                  <span v-if="scope.row.remark == ''">--</span>
                  <span v-else>{{ scope.row.remark }}</span>
                </p>
              </div>
            </template>
          </el-table-column>
          <!-- 柜型 -->
          <el-table-column prop="address" label="柜型">
            <template slot-scope="scope">
              <p class="tcolor" style="font-size: 12px">
                {{ scope.row.cabinetShape }}
              </p>
            </template>
          </el-table-column>
          <!-- 现舱 -->
          <el-table-column prop="date" label="">
           
            <template>
              <div class="xiancang">
                <span class="xc">现舱</span>
                <span class="xszx">线上专享</span>
              </div>
            </template>
          </el-table-column>
          <!-- 价位 -->
          <el-table-column prop="date" label="" width="300">
             <template slot="header" slot-scope="scope">
                <div class="flex headerOrderBy">
             
              <div> 排序 </div> 
                
              <div style="margin-left:5px;margin-right:5px">
                    <el-select v-model="orderByModel" class="sortInput"   style="width:130px;height:30px" placeholder="请选择">
                        <el-option
                          v-for="item in orderByList"
                          :key="item.value"
                          :label="item.name"
                        
                          :value="item.value">
                        </el-option>
                      </el-select>
                      
                    </div>
                    <div  class="caret-wrapper" @click="sortClick">
                          <i class="el-icon-caret-top" :style="sortTx == 'asc' ? 'color:rgba(0, 186, 253, 1)' : ''"></i>
                          <i class="el-icon-caret-bottom" :style="sortTx == 'desc' ? 'color:rgba(0, 186, 253, 1)' : ''"></i>
                      
                      </div>
                </div>
           
            </template>
            <template slot-scope="scope">
              <div>
                <p class="price">${{ scope.row.cabinetAmount }}</p>
                <p style="font-size: 12px; color: #acacac">
                  舱位&nbsp;&nbsp;{{ scope.row.stockNum }}
                </p>
              </div>
            </template>
          </el-table-column>
          <!-- 抢购/客服 -->
          <el-table-column prop="date" label="" width="170">
            <template slot-scope="scope">
              <div class="cls">
                <span class="qg tx" @click="orderInfo(scope.row)">抢购</span>
                <span class="kf tx" @click="service(scope.row)">
                  <img src="./service.png" alt="" />
                  <span>客服</span>
                </span>
              </div>
            </template>
          </el-table-column>
          <div slot="empty" style="text-align: center" class="empty">
            <div>
              <img
                src="./../../assets/images/zanwu.png"
                alt=""
                srcset=""
                style="width: 250px; height: 184px; padding-top: 20px"
              />
            </div>
            <div v-show="params.startSeaport && params.targetSeaport">
              {{ params.startSeaport }} -- {{ params.targetSeaport }}
            </div>
            <div>
              没有查询到舱位结果，<span
                style="
                  text-decoration: underline;
                  color: #0062ff;
                  cursor: pointer;
                "
                @click="hangxiangShow"
                >点击这里立即发布寻舱需求</span
              >
            </div>
          </div>
        </el-table>
      <!-- -->
        <!-- <div class="loadingCenter" v-show="loading" >
          <img src="../../assets/images/loading.gif" alt="" srcset="">
        </div> -->
        <div class="loading"  v-show="loading" >
          <div class="loading-animation">
              <div class="loading-item item1"> </div>
              <div class="loading-item item2"></div> 
              <div class="loading-item item3"></div>
           </div>
        </div>
        <div  class="loadingCenter" v-show="noData">
          无更多数据
        </div>
      </div>
    </div>
    
      <sellingPort ref="sellingPort"></sellingPort>  
            <seekPort ref="seekPort" ></seekPort> 
    <Footer-botton></Footer-botton>
  </div>
</template>
<script>
import HeaderTop from "../common/header.vue";

import FooterBotton from "../common/footer";
import seekPort from "../common/seekPort.vue";
import sellingPort from '../common/sellingPort.vue'
let _ = require("lodash");
import elTableInfiniteScroll from "el-table-infinite-scroll";
export default {
  components: { HeaderTop, FooterBotton, seekPort ,sellingPort},
  directives: {
    "el-table-infinite-scroll": elTableInfiniteScroll,
  },
  data() {
    return {
      params: {
        startSeaport: '',
        changeSeaport: '',
        sailTimeStart: '',
        sailTimeEnd: '',
        targetSeaport: '',
        shipCompanyName: '',
        cabinetShape: '',
        pageSize: 15,
        pageIndex: 1,
        status: 1,
        order: "",
      },
      //   起始港
      FreightPortList1: [],
      //   目的港
      FreightPortList3: [],
      //   船司
      companyList: [],
      //   柜型
      getFreightDefineEnumsList: [],
      //   预计开船时间
      value1: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //禁止选择今天以前的时间
        },
      },
      tableData: [],
      busy: false,
      loadHeight: 0,
      loading: false,
      orderByModel:'',
      scrollHeight:'',
      orderByList:[
      {
        value:'1',
        name:'价格排序'
      },
        {
        value:'2',
        name:'开船时间排序'
      },
      ],
      sortTx:'',
      noData:false,
      yearObj:{},
    };
  },
  created() {
    let token = localStorage.getItem("token");
    if (!token || token == "null" || token == "" || token == "undefined") {
      token = "";
    } else {
      token = token;
    }
    this.token = token;
    // 起始港
    this.getFreightPort();
    // 目的港
    this.getFreightPort1();
    // 柜型
    this.getFreightCompany();
    // 船司
    this.getFreightDefineEnums();
    //  this.params.startSeaport = '深圳'
    //     this.params.targetSeaport = 'LOS ANGELES,CA'
     if(this.$route.query.value){
            let params = JSON.parse(this.$route.query.value)
             if(params.value1 != null && params.value1.length != 0){
                // this.params.startDate = this.value1 + ' 00:00:00'
                // this.params.eneDate = this.value1 + ' 23:59:59'
                this.params.sailTime = this.value1 + ' 00:00:00'
            } else {
                this.params.startDate = ''
                this.params.eneDate = ''
            }
            //  this.params = JSON.parse(this.$route.query.value)
            params.pageSize = 15
            this.params =params
            // debugger
        } else {
           this.params= {
            startSeaport: '',
            changeSeaport: '',
            sailTimeStart: '',
            sailTimeEnd: '',
            targetSeaport: '',
            shipCompanyName: '',
            cabinetShape: '',
            pageSize: 15,
            pageIndex: 1,
            status: 1,
            order: '',
          }
          
        }
    
    this.getData();
  },
  mounted() {
    (function (a, b, c, d, e, j, s) {
      a[d] =
        a[d] ||
        function () {
          (a[d].a = a[d].a || []).push(arguments);
        };
      (j = b.createElement(c)), (s = b.getElementsByTagName(c)[0]);
      j.async = true;
      j.charset = "UTF-8";
      j.src = "https://static.meiqia.com/widget/loader.js";
      s.parentNode.insertBefore(j, s);
    })(window, document, "script", "_MEIQIA");
    _MEIQIA("entId", "3b5f06f295f4877f71e2e3b515160807");
    _MEIQIA("withoutBtn");
    _MEIQIA("hidePanel");
     window.addEventListener('scroll', this.scrollBottom)
  },
  methods: {
      
		
		
		//获取当天为那年那周
		 getWeekNum(date) {
			var nowDate = date; //保存传入的日期
			var startType = 0; //开始类型  0为周天开始，1为周一开始
			var addZero = 0; //是否补充0    0不满足1周按去年最后一周算，1不满足1周按第一周算
			var firstDayDate = this.getWeekDate(nowDate.getFullYear(), startType);
      console.log(date,'date')
			var diffDay = Math.ceil((nowDate.getTime() - firstDayDate.getTime()) / 1000 / 3600 / 24 / 7) + addZero;
      console.log(diffDay)
			if (diffDay > 0) { //如果不为0 则返回
      	var lastYearDate = this.getWeekDate(nowDate.getFullYear() - 1, startType); //获取上年第一个周日(周一)的日期
          // if(diffDay == 52){
          //   return  firstDayDate.getFullYear() + "年" + diffDay + "周"  + '-' + Number(firstDayDate.getFullYear() + 1) + "年" + 1 + "周";
          // } else {
            	return firstDayDate.getFullYear() + "年" + diffDay + "周" +   "-" + Number(diffDay + 1)  + "周";
          // }
			} else { //如果为0 则计算去年最后一周
				var lastYearDate = this.getWeekDate(nowDate.getFullYear() - 1, startType); //获取上年第一个周日(周一)的日期
				var endYearDate = new Date(nowDate.getFullYear() - 1 + "-12-31");
				var lastYearDiffDay = Math.ceil((endYearDate.getTime() - lastYearDate.getTime()) / 1000 / 3600 / 24 / 7) +
					addZero;
				return lastYearDate.getFullYear() + "年" + lastYearDiffDay + "周"  + '-' + Number(lastYearDate.getFullYear() + 1) + "年" + 1 + "周";
			}
		},
		//用于获取某年第一个周日(周一)的日期
		 getWeekDate(year, startType) {
			var firstDayDate = new Date(year + "-01-01");
			var firstWeed = firstDayDate.getDay(); //获取当年第一天是周几
      console.log('获取当年第一天是周几',firstWeed,new Date(year + "-01-01"))
			var dayNum = 0;
			if (startType == 0) {
				//按照周天开始
				dayNum = 7 - ((firstWeed == 0) ? 7 : firstWeed);
			} else if (startType == 1) {
				//按照周一开始
				dayNum = 8 - ((firstWeed == 0) ? 7 : (firstWeed == 1 ? 8 : firstWeed));
			}
			firstDayDate.setDate(firstDayDate.getDate() + dayNum);
      console.log(firstWeed,'firstWeed')
			// console.log(firstDayDate.getFullYear() + "年第一天为：周" + firstWeed + "(0为周天),距离第一个周日(周一)" + dayNum + "天，日期：1月" +
			// 	firstDayDate.getDate() + "号");
      
			return firstDayDate;
		},
		//时间格式化
		 dateFormat(fmt, date) {
			let ret;
			const opt = {
				"Y+": date.getFullYear().toString(), // 年
				"m+": (date.getMonth() + 1).toString(), // 月
				"d+": date.getDate().toString(), // 日
				"H+": date.getHours().toString(), // 时
				"M+": date.getMinutes().toString(), // 分
				"S+": date.getSeconds().toString() // 秒
			};
			for (let k in opt) {
				ret = new RegExp("(" + k + ")").exec(fmt);
				if (ret) {
					fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
				};
			};
			return fmt;
		},
      // getWeek(dt) {
      //     let d1 = new Date(dt);
      //     let d2 = new Date(dt);
      //     d2.setMonth(0);
        
      //     d2.setDate(3);//3 周一为本周第一天；2 周日为本周第一天；1 周六为本周第一天
      //     let rq = d1 - d2;
      //     // console.log()
      //     // d1.getFullYear()
      //     // this.getFullYear
      //     let days = Math.ceil(rq / (24 * 60 * 60 * 1000));
      //     let num = Math.ceil(days / 7);
      //     let yearObj = {
      //       num:num ,
      //       years:d1.getFullYear()
      //     }
      //     console.log(yearObj,'yearObj')
      //     return yearObj;
      // },
     fabucangwei(){
             let token = localStorage.getItem('token')
             if(!token || token == 'null' || token == '' || token == 'undefined'){
                this.token = ''
                  this.$refs.headerTop.login()
            } else {
                this.$refs.sellingPort.errshowoLoad()
            }
        },
      hangxiangShow(){
        
          let token = localStorage.getItem('token')
          if(!token || token == 'null' || token == '' || token == 'undefined'){
            // next('/login')
            this.token = ''
              this.$refs.headerTop.login()
            
        } else {
            // this.$router.push('/seek')
            // this.dialogVisible = true
            // this.zIndex = true
              this.$refs.seekPort.showDialogVisible()
        }
      
    },
    sortClick(){
      if(this.orderByModel == ''){
        this.$message.error('请选择排序')
        return false
      }
      if(this.sortTx == ''){
        this.sortTx = 'desc'
      } else if(this.sortTx == 'desc'){
        this.sortTx = 'asc'
      } else if(this.sortTx == 'asc'){
        this.sortTx = ''
      }
      if(this.orderByModel == '1'){
         if(this.sortTx != ''){
           this.params.order = 'cabinet_amount' + ' ' + this.sortTx
         } else {
           this.params.order = ''
         }
      } 
      if(this.orderByModel == '2'){
        if(this.sortTx != ''){
           this.params.order = 'sail_time' + ' ' + this.sortTx
         } else {
           this.params.order = ''
         }
      }
      
      console.log(this.params.order )
      // return
      this.tableData = []
      this.params.pageIndex = 1
      this.getData()
      this.noData = this.false
    },
    scrollBottom() {
				   //变量scrollTop是滚动条滚动时，距离顶部的距离
				   let scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
          
				   //变量windowHeight是可视区的高度
				   let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
				   //变量scrollHeight是滚动条的总高度
				   let scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
            //  console.log(scrollHeight)
            //  console.log(scrollTop,scrollHeight,(Math.floor(scrollTop)),Math.floor(windowHeight), Math.floor(scrollHeight))
				    //滚动条到底部的条件
            if(this.noData ){
               this.loading = this.false
              return false
            }
            if(this.tableData.length == 0){
              return false
            }
						if(Math.floor(scrollTop)+ Math.floor(windowHeight)  == Math.floor(scrollHeight) && !this.loading){
            
						  this.loading = true
              this.scrollHeight = windowHeight
              this.layoutData()
             
						  //  var footer=document.getElementById('footer')
						  //  footer.style.display="block"
						}
    },
    tops(){
        //使得返回顶部过渡置增加交互效果
       setTimeout(()=>{
          // window.scrollTo(0, this.scrollHeight)
          // var timer = setInterval(() => document.documentElement.scrollTop <= this.scrollHeight ? clearInterval(timer) : window.scrollTo(0, this.scrollHeight), 1)
          // var timer = setInterval(()=>{
          window.scrollTo(0, this.scrollHeight)
            //  clearInterval(timer)
          // },1)
          // clearInterval(timer)
        
          this.loading = false
       },300)
    },
    layoutData(){
        if (this.value1 != null && this.value1.length != 0) {
        // this.params.sailTimeStart = this.value1[0] + " 00:00:00";
        // this.params.sailTimeEnd = this.value1[1] + " 23:59:59";
      } else {
        // this.params.sailTimeStart = "";
        // this.params.sailTimeEnd = "";
      }

      if (this.busy) {
        return false;
      }
      // this.params.order = 'cabinet_amount asc'
      this.$post("/freight/searchFreight4appGatherSimple", this.params).then(
        (res) => {
          res.data.list.forEach((ele) => {
            let data1 = new Date(ele.sailTime).getDay();
            let data2 = new Date(ele.closePassTime).getDay();
            if (data1 == 0) {
              ele.weekSail = 7;
            } else {
              ele.weekSail = data1;
            }
            if (data2 == 0) {
              ele.weekcloseFile = 7;
            } else {
              ele.weekcloseFile = data2;
            }
            if (ele.surchargeAmountDetail) {
              if (
                ele.surchargeAmountDetail != "[]" &&
                ele.surchargeAmountDetail != "[\u0000]"
              ) {
                ele.surchargeAmountDetail = JSON.parse(
                  ele.surchargeAmountDetail
                );
                let usdTotal = 0;
                let cnyTotal = 0;
                let cnyList = [];
                let usdList = [];
                ele.surchargeAmountDetail.forEach((ele1) => {
                  // debugger
                  if (ele1.feeType == "CNY") {
                    cnyTotal += ele1.fee;
                  }
                  if (ele1.feeType == "USD") {
                    usdTotal += ele1.fee;
                  }
                  ele.usdTotal = usdTotal;
                  ele.cnyTotal = cnyTotal;
                });
              } else {
                ele.surchargeAmount = 0;
                delete ele.surchargeAmountDetail;
              }
            }
            if (ele.supplyBizUserName) {
              ele.mingzi = ele.supplyBizUserName.substr(0, 1) + "*";
            } else {
            }

            if (ele.remark == "备注:") {
              delete ele.remark;
            }
            if (ele.depositAmount == 0) {
              ele.depositAmount = "--";
            } else if (!ele.depositAmount) {
              ele.depositAmount = "--";
            }
            ele.startPortName1 = this.getParenthesesStr(ele.startPortName);
            ele.compositeGrade = 5;
            ele.closeFileTime = ele.closeFileTime.substr(0, 10);
            ele.closePassTime = ele.closePassTime.substr(0, 10);
            ele.sailTime = ele.sailTime.substr(0, 10);
          });

          if (res.data.list.length != 0) {
            this.tableData = this.tableData.concat(res.data.list);
           
            this.params.pageIndex += 1;
           
              this.tops()
              return false
            // this.throttleDelay += 500
          }
          if(res.data.list.length == 0){
            this.noData = true
          }
          console.log(this.tableData);
        }
      );
    },
    hangxiangShow(){
           
             let token = localStorage.getItem('token')
             if(!token || token == 'null' || token == '' || token == 'undefined'){
                // next('/login')
                this.token = ''
                 this.$refs.headerTop.login()
                
            } else {
                // this.$router.push('/seek')
                // this.dialogVisible = true
                this.zIndex = true
                 this.$refs.seekPort.showDialogVisible(this.params.startSeaport,this.params.targetSeaport)
            }
          
        },
    // 获取滚动条当前的位置
    getScrollTop() {
      var scrollTop = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }
      return scrollTop;
    },
    // 获取当前可视范围的高度
    getClientHeight() {
      var clientHeight = 0;
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight = Math.min(
          document.body.clientHeight,
          document.documentElement.clientHeight
        );
      } else {
        clientHeight = Math.max(
          document.body.clientHeight,
          document.documentElement.clientHeight
        );
      }
      return clientHeight;
    },

    // 获取文档完整的高度
    getScrollHeight() {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight
      );
    },
    // 滚动事件触发下拉加载
    onScroll() {
      // let loadHeight =

      if (
        this.getScrollHeight() - this.getClientHeight() - this.getScrollTop() <=
        1000
      ) {
        // if (this.status === 1) {
        if (!this.busy) {
          this.getData();
        }
      }
    },
    //   起始港
    getFreightPort(val) {
      this.params.startSeaport = val;
      this.$post("/system/getFreightPort", { name: val }).then((res) => {
        this.FreightPortList1 = res.data.list;
         if(!val){
                    this.FreightPortList1.unshift({
                        cname:'深圳',
                        name:'SHENZHEN',
                        id:999999
                    })
                }
      });
    },
    // 目的港
    visibleChange(bool) {
      if (bool) {
        this.stylePort = bool;
      }
    },
    blurChange() {
      this.stylePort = false;
    },
    getFreightPort1(val) {
      this.stylePort = false;
      this.params.targetSeaport = val;
      this.$post("/system/getFreightPort", { type: "target", name: val }).then(
        (res) => {
          this.FreightPortList3 = res.data.list;
        }
      );
    },
    // 柜型
    getFreightCompany(val) {
      this.$post("/system/getFreightCompany", { name: val }).then((res) => {
        this.companyList = res.data.list;
      });
    },
    // 船司
    getFreightDefineEnums() {
      this.$get("/system/getFreightDefineEnums").then((res) => {
        this.getFreightDefineEnumsList = res.data;
      });
    },
    initData1(){
      this.initData()
      this.getFreightPort1()
      this.getFreightPort()
    },
    // 搜索
    initData: _.throttle(function () {
      this.params.pageIndex = 1;
      this.noData = false
      // if (this.params.startSeaport == "") {
      //   this.params.startSeaport = "深圳";
      //   this.$set(this.params, "startSeaport", "深圳");
      // }
      // if (this.params.targetSeaport == "") {
      //   this.params.targetSeaport = "LOS ANGELES,CA";
      //   this.$set(this.params, "targetSeaport", "LOS ANGELES,CA");
      // }
      // debugger
      console.log(this.params);
      let histroyData = localStorage.getItem("histroyData");
      if (this.params.startSeaport && this.params.targetSeaport) {
        if (!histroyData) {
          histroyData = [];
        } else {
          histroyData = JSON.parse(histroyData);
        }
        histroyData.unshift({
          startSeaport: this.params.startSeaport,
          targetSeaport: this.params.targetSeaport,
        });
        localStorage.setItem("histroyData", JSON.stringify(histroyData));
      }
      if (this.value1 != null && this.value1.length != 0) {
        this.params.sailTime = this.value1 + " 00:00:00";
        // this.y
          var d = new Date(this.params.sailTime);
            console.log(d.getDay())
          d.setFullYear(d.getFullYear());
          d.setMonth(d.getMonth());
          d.setDate(d.getDate());
          console.log(d)
          console.log();
        this.yearObj = this.getWeekNum(d)
        // this.params.eneDate = this.value1[1] + " 23:59:59";
      } else {
        this.params.sailTime = ''
        // this.params.startDate = "";
        // this.params.eneDate = "";
      }
      let params = this.params;
      params.userId = localStorage.getItem("empId");
      let userInfo = localStorage.getItem("userInfo");
      if (userInfo != null) {
        params.mobileNum = JSON.parse(userInfo).user.phone;
      }
      //  return
      this.$post(
        "/index/click?startDate=" +
          this.params.startDate +
          "&=" +
          this.eneDate +
          "&startSeaport=" +
          this.params.startSeaport +
          "&targetSeaport=" +
          this.params.targetSeaport +
          "&companyName=" +
          this.params.shipCompanyName +
          "&cabinetShape=" +
          this.params.cabinetShape +
          "&mobileNum=" +
          params.mobileNum +
          "&userId=" +
          this.params.userId,
        this.params
      ).then((res) => {
        // this.tableData=res.data.list
        this.tableData = [];
        this.getData();
      });
    }, 3000),
    
    // 数据
    getData: _.throttle(function () {
      if (this.value1 != null && this.value1.length != 0) {
        // this.params.sailTimeStart = this.value1[0] + " 00:00:00";
        // this.params.sailTimeEnd = this.value1[1] + " 23:59:59";
      } else {
        // this.params.sailTimeStart = "";
        // this.params.sailTimeEnd = "";
      }

      if (this.busy) {
        return false;
      }
      this.tableData = []
      // this.params.order = 'cabinet_amount asc'
      this.$post("/freight/searchFreight4appGatherSimple", this.params).then(
        (res) => {
          res.data.list.forEach((ele) => {
            let data1 = new Date(ele.sailTime).getDay();
            let data2 = new Date(ele.closePassTime).getDay();
            if (data1 == 0) {
              ele.weekSail = 7;
            } else {
              ele.weekSail = data1;
            }
            if (data2 == 0) {
              ele.weekcloseFile = 7;
            } else {
              ele.weekcloseFile = data2;
            }
            if (ele.surchargeAmountDetail) {
              if (
                ele.surchargeAmountDetail != "[]" &&
                ele.surchargeAmountDetail != "[\u0000]"
              ) {
                ele.surchargeAmountDetail = JSON.parse(
                  ele.surchargeAmountDetail
                );
                let usdTotal = 0;
                let cnyTotal = 0;
                let cnyList = [];
                let usdList = [];
                ele.surchargeAmountDetail.forEach((ele1) => {
                  // debugger
                  if (ele1.feeType == "CNY") {
                    cnyTotal += ele1.fee;
                  }
                  if (ele1.feeType == "USD") {
                    usdTotal += ele1.fee;
                  }
                  ele.usdTotal = usdTotal;
                  ele.cnyTotal = cnyTotal;
                });
              } else {
                ele.surchargeAmount = 0;
                delete ele.surchargeAmountDetail;
              }
            }
            if (ele.supplyBizUserName) {
              ele.mingzi = ele.supplyBizUserName.substr(0, 1) + "*";
            } else {
            }

            if (ele.remark == "备注:") {
              delete ele.remark;
            }
            if (ele.depositAmount == 0) {
              ele.depositAmount = "--";
            } else if (!ele.depositAmount) {
              ele.depositAmount = "--";
            }
            ele.startPortName1 = this.getParenthesesStr(ele.startPortName);
            ele.compositeGrade = 5;
            ele.closeFileTime = ele.closeFileTime.substr(0, 10);
            ele.closePassTime = ele.closePassTime.substr(0, 10);
            ele.sailTime = ele.sailTime.substr(0, 10);
          });

          if (res.data.length != 0) {
            this.tableData = this.tableData.concat(res.data.list);
            // setTimeout(() => {
              this.busy = false;
                this.params.pageIndex = 2;
            // }, 3000);
            // this.throttleDelay += 500
          }
          if(res.data.list.length == 0){
            this.loading = false
          }
          //   if(res.data.list.length == 0){
          //   this.noData = true
          // }
          console.log(this.tableData);
        }
      );
    }, 3000),
    getParenthesesStr(text) {
      let result = "";
      // debugger
      // if (isObjEmpty(text))
      // return result
      let regex = /\((.+?)\)/g;
      let options = text.match(regex);
      // if (!isObjEmpty(options)) {
      let option = options[0];
      // if (!isObjEmpty(option)) {
      result = text.replace(/\((.+?)\)/g, "");
      // }
      // }
      // console.log(result)
      return result;
    },
    // 联系客服
    service(val) {
      // debugger
      let token = localStorage.getItem("token");

      if (!token || token == "null" || token == "" || token == "undefined") {
        // next('/login')
        this.token = "";
        this.$refs.headerTop.login()
      } else {
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        _MEIQIA("metadata", {
          contact:
            "姓名：" +
            userInfo.user.userName +
            "公司名称：" +
            userInfo.user.shopAddress,
          email: userInfo.user.registerMail
            ? userInfo.user.registerMail
            : "用户未注册邮箱",
          tel: userInfo.user.phone,
          weibo: "",
          comment: "航线id" + val.id,
        });
        _MEIQIA("showPanel");
      }
    },
    // 抢购
    orderInfo(val) {
      // if()
      let token = localStorage.getItem("token");
      if (this.value1 != null && this.value1.length != 0) {
        this.params.sailTimeStart = this.value1[0] + " 00:00:00";
        this.params.sailTimeEnd = this.value1[1] + " 23:59:59";
      } else {
        this.params.sailTimeStart = "";
        this.params.sailTimeEnd = "";
      }
      if (this.params.startSeaport == undefined) {
        this.params.startSeaport = "";
      }

      if (this.params.targetSeaport == undefined) {
        this.params.targetSeaport = "";
      }

      let params = {
        startSeaport: this.params.startSeaport,
        targetSeaport: this.params.targetSeaport,
        companyName: this.params.shipCompanyName,
        cabinetShape: this.params.cabinetShape,
        startDate: this.params.sailTimeStart,
        endDate: this.params.sailTimeEnd,
      };
      this.$post("/index/click", params).then((res) => {
        console.log(res);
      });

      if (!token || token == "null" || token == "" || token == "undefined") {
        // next('/login')
        this.token = "";
         this.$refs.headerTop.login()
      } else {
        this.$router.push(
          "/subOrder?id=" +
            val.id +
            "&freightVoSkuList=" +
            JSON.stringify(val.freightVoSkuList)
        );
      }
    },
    // 加载
    scrollData(e) {
      console.log("111");
    },
  },
};
</script>
<style lang="scss" scoped>
.con {
  background-color: #fff;

  // height: 100%;
  overflow-y: auto;
}
.searchbox {
  position: relative;
  width: 100%;
  height: 260px;
  background: linear-gradient(#73daff, #fff);
}
.search {
  position: absolute;
  width: 1188px;
  left: 50%;
  top: 100px;
  transform: translate(-50%, -90%);
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#cons {
  width: 100%;
  background-color: pink;
  margin: -180px auto 30px;
  background-color: #fff;
  border-radius: 15px;
  // height: 2000px;
  //  height: 100%;
  // overflow-y: auto;
  .contents {
    width: 1188px;
    margin: 0 auto;
    position: relative;
  }
}
.name1 {
  font-size: 14px;
}
.name2 {
  font-size: 12px !important;
  color: #acacac;
}
.xiancang {
  text-align: center;
  font-size: 13px;
  .xc {
    width: 30%;
    color: #fff;
    border: solid 1px #ff0000;
    background-color: #ff0000;
    display: inline-block;
    border-radius: 16px 0 0 16px;
  }
  .xszx {
    padding: 0 5px;
    display: inline-block;
    border: solid 1px #ff0000;
    color: #ff0000;
    border-radius: 0 16px 16px 0;
  }
}
.tx {
  font-size: 12px;
  border-radius: 5px;
  line-height: 25px;
  width: 60px;
  text-align: center;
  cursor: pointer;
}
.qg {
  display: inline-block;
  background-color: #ff6b00;
  color: #fff;
  margin-right: 5px;
}
.kf {
  display: inline-block;
  border: 1px solid #1677ff;
  color: #1677ff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.price {
  color: #ff0000;
  font-size: 18px;
}
.zz {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  color: #acacac;
  height: 40px;
}
// .method {
//   // text-align: center;
// }
.tcolor {
  color: #acacac;
}
// 滚动条样式

::-webkit-scrollbar {
  width: 5px;
}
.el-table--fit {
  border-radius: 15px;
  box-shadow: 0 0 4px #e5e5e5;
  padding: 0 5px;
}
.cls {
  display: flex;
}
/*鼠标移入某行时的背景色*/
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #fff;
}
::v-deep .el-table--enable-row-hover .el-table__body tr > td,
::v-deep .el-table .cell {
  text-align: center;
  line-height: 30px;
}
::v-deep .el-table_1_column_4 .cell {
  text-align: left;
  padding-left: 30px;
}
.el-form {
  width: 1188px;
  display: flex;
  justify-content: space-between;
}
::v-deep .el-table__body-wrapper {
  height: 100% !important;
}
::v-deep .el-input--small .el-input__inner {
  height: 48px !important;
}
::v-deep .el-input__icon {
  font-weight: 900 !important;
  color: #333 !important;
}
.empty {
  padding-top: 100px;
  padding-bottom: 100px;
}
::v-deep .el-table::before {
  z-index: -1 !important;
}
.loadingCenter{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.loadingCenter img{
  width: 150px;
  height: 200px;
}
.loading {
    padding: 40px 0;
}
.loading .loading-animation {
    text-align: center;
    height: 20px;
    width: 25px;
    margin: 0 auto;
    position: relative;
}

@keyframes mymove
{
  0% {
      transform: translateX(-20px);
      z-index: 0;
  }


  50% {
      transform: translateX(20px);
      z-index: 1;
  }

  100% {
      transform: translateX(-20px);
      z-index: 0;
  }
}


.loading .loading-animation .loading-item {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    margin: 0 5px;
    position: absolute;
    left: 0;
}
.loading .loading-animation .loading-item {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    margin: 0 5px;
    position: absolute;
    left: 0;
}
.item1 {
    animation-delay: 0s;
    background-color: #969696;
    animation: mymove 1.2s infinite;
  
}
.loading .loading-animation .item2 {
    background-color: #adadad ;
}
.loading .loading-animation .item3 {
    animation-delay: .5s;
    background-color: #d7d7d7;
    
    animation: mymove2 1.2s infinite;
}
@keyframes mymove2
{
0% {
    transform: translateX(20px);
    z-index: 0;
}

50% {
    transform: translateX(-20px);
    z-index: 1;
}

100% {
    transform: translateX(20px);
    z-index: 0;
}
}
.mymove2{
    animation-duration: 1.2s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;

}
.loading .loading-animation .loading-item {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    margin: 0 5px;
    position: absolute;
    left: 0;
}
::v-deep .cell{
  position: relative;
}
.headerOrderBy {
  // position: absolute;
  top: 12px;
  right: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  // z-index: 1;
}

 .caret-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    // height: 34px;
    // width: 24px;
    justify-content: center;
    vertical-align: middle;
    cursor: pointer;
    overflow: initial;
    position: relative;
    // background: red;
}
.sort-caret {
    width: 0;
    height: 0;
    border: 6px solid transparent;
    position: absolute;
    left: 7px;
}
.weekNum{
  margin-bottom: 10px;
}
</style>